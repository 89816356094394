@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  /* height: 100%; */
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.sidebar{
  height: 100vh;
  position: sticky;
  position: -webkit-sticky;
  top: 0
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.leaflet-container{
  height: 100vh;
}
